body {
  margin: 0;
  font-family: 'Lato', 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  font-family: 'Lato', 'Open Sans', sans-serif !important;
}

.MuiAccordionSummary-root.Mui-disabled {
  opacity: 0.5 !important;
}

.logo-loader {
  position: fixed;
  top: 50%;
  right: 50%;
  transform: translate(50%, -50%);
}

.animation-loader-pulse {
  animation-duration: 1.5s;
  animation-timing-function: ease-in-out;
  animation-delay: 0.5s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-fill-mode: none;
  animation-play-state: running;
  animation-name: pulse;
}


@supports (-webkit-line-clamp: 2) {
  .lineclamp { 
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: initial;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

@keyframes pulse {
  0% {
    opacity: 1;
  }

  50% {
    opacity: 0.4;
  }

  100% {
    opacity: 1;
  }
}